@media (max-width: 1024px) {}

@media (min-width: 1024px) {

    .container {
        position: relative;
        margin: 0;
        font-size: 1.1em;
        line-height: 40px;
    }

    .skelet {
        position: absolute;
        bottom: 7px;
        width: 200px;
        height: 26px;
        background: #333;
        border-radius: 10px;
    }

}
  