@media (max-width: 1024px) {
    .server { display: none; color: #fff; }
    /*.serverList { margin-top: 102px; border-radius: 8px; }
    .server-container { margin: 0 auto; padding: 24px; transition: .4s; }
    .server-container { margin-bottom: 42px; background: #202124; }
    .server-title { position: relative; margin: 20px 0 20px 0; height: 44px; font-size: 2em; }
    .server-title-skelet { position: absolute; bottom: 0; width: 200px; height: 26px; background: grey; border-radius: 10px; }
    .server-image-container { padding: 20px; }
    .server-container .server-image-container { width: calc(100% - 40px); height: 160px; background: grey; background-position: center; background-size: cover; border-radius: 10px; }
    /*.server-container:nth-child(1) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/drift.jpg"); background-position: center; background-size: cover; border-radius: 10px; }
    .server-container:nth-child(2) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/drift-playground.jpg"); background-position: center; background-size: cover; border-radius: 10px; }
    .server-container:nth-child(4) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/fort-51.jpg"); background-position: center; background-size: cover; border-radius: 10px; }
    .server-container:nth-child(5) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/klutch-kickers.jpg"); background-position: center; background-size: cover; border-radius: 10px; }*/
    /*.btn-join { padding: 12px 24px; width: 84px; background: #2D2D31; color: #fff; font-size: 1.2em; border: none; border-radius: 6px; transition: .2s; }
    .btn-join:hover { background: #E88873; color: #000; cursor: pointer; }
    .server-header-container { display: grid; grid-template-columns: auto 84px; }
    .server-btn-join-container { display: flex; justify-content: center; align-items: center; }
    .server-btnLink-container { display: block; }*/
}
@media (min-width: 1024px) {
    .relative {
        position: relative;
    }
    .server { color: #fff; }
    .serverList { border-radius: 8px; }
    .serverContainer { margin: 0 auto; width: 1000px; transition: .4s; border-radius: 10px; }
    .serverContainer { margin: 0 auto; width: 1000px; padding: 16px; transition: .4s; border-radius: 10px; }
    .serverContainer { margin-bottom: 32px; background: #33333320; border: 2px solid #202124; }

    .serverContainer:hover { border: 2px solid #eeeeee60; cursor: pointer }
    
    .serverImageContainer { padding: 20px; }
    .serverContainer .serverImageContainer { display: none; width: calc(100% - 40px); height: 140px; background: grey; background-position: center; background-size: cover; border-radius: 10px; }
    .serverDownloadContainer { height: 40px }
    .btnOnline { position: absolute; display: flex; justify-content: center; align-items: center; width: 40px; height: 40px; background: #E8887320; color: #000; font-size: 1.2em; border: 2px solid #E8887320; border-radius: 6px; transition: .2s; }
    .btnOnlineActive { background: #E88873; }
    .btnOnlineDescription { position: absolute; opacity: 0; top: 40px; margin-top: 10px; margin-left: -43px; padding: 4px 14px; background: black; font-size: 0.8em; border-radius: 6px; transition: .4s; }
    .onlineLink:hover + .btnOnlineDescription { opacity: 1; }
    .btnJoin { padding: 7px 24px; width: 84px; height: 40px; background: #2D2D31; color: #fff; font-size: 1.1em; border: none; border-radius: 6px; transition: .2s; }
    
    .btnDownload2 { width: 40px; height: 40px; background: #2D2D31; color: #fff; font-size: 1.1em; border: none; border-radius: 6px; transition: .2s; }
    .btnJoin:hover { background: #E88873; color: #000; cursor: pointer; }
    
    .btnDownload2:hover { background: #E88873; color: #000; cursor: pointer; }
    .serverHeaderContainer { display: grid; grid-template-columns: 84px 500px 150px auto 54px 10px 84px; height: 40px; }
    .serverDescriptionContainer { display: grid; grid-template-columns: 84px 500px 150px auto 54px 84px; height: 40px; }
    .serverBtnOnlineContainer { position: relative; justify-content: center; align-items: center; }
    .serverBtnJoinContainer { display: flex; justify-content: center; align-items: center; }
    .serverBtnLinkContainer { display:block; }
    .serverBtnMenuContainer { display:block; }
    .serverBtnDownloadContainer { position: relative; justify-content: center; align-items: center; }
    
    .btnDownloadActive { background: #E88873; }
    .btnDownloadDescription { position: absolute; opacity: 0; top: 40px; margin-top: 10px; margin-left: -40px; padding: 4px 14px; width: 90px; background: black; font-size: 0.8em; border-radius: 6px; transition: .4s; }
    .btnDownload:hover + .btnDownloadDescription { opacity: 1; }
    .serverBtnJoinSkelet { width: 84px; height: 40px; background: #2D2D31; border-radius: 6px; }
    .serverBtnActionSkelet { width: 40px; height: 40px; background: #333; border-radius: 6px; }
    .serverAuthorContainer { position: relative; }
    .serverAuthorSkelet { margin: 6px 0; width: 120px; height: 28px; background: #2D2D31; border-radius: 6px; }
    .serverBtnAuthor { position: absolute; bottom: 6px; color: #ddd; background: transparent; font-size: 0.9em; border: none }


    .searchContainer { margin: 0 auto; margin-top: 30vh; width: 1000px; }

    .searchContainerAuthor { display: none; margin: 0 auto; padding: 8px 30px 8px 16px; transition: .4s; border-radius: 10px; }
    .searchContainerAuthor { margin-bottom: 32px; background: #E88873; }

    .searchContainerAuthor:hover { background: #fff; cursor: pointer; }

    .author2 { position: relative; transition: .4s; }
    .authorClose { position: absolute; bottom: 0; font-size: 1.2em; }

}
  