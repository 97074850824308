@media (max-width: 1024px) {
    .modalDownload { display: none; }
}
@media (min-width: 1024px) {

    .modal {
        position: fixed;
        display: none;
        justify-content: center;
        align-items: center;
        top: 0;
        width: 100vw;
        height: 100vh;
        color: #fff;
        background: #00000099;
        z-index: 10000;
    }

    .container {
        padding: 8px 16px;
        position: fixed;
        width: 600px;
        height: 220px;
        color: #fff;
        background: #202124;
        border-radius: 10px;
        box-shadow: 0 0 50px #000;
    }

    .title {
        font-size: 1.8em;
    }

    .btnClose {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 10px 20px;
        color: #fff;
        background: #2D2D31;
        border-radius: 6px;
        border: none;
        transition: .4s;
    }

    .btnClose:hover {
        color: #000;
        background: #E88873;
        cursor: pointer
    }

    .block {
        display: block;
    }

    .link {
        display: inline;
        vertical-align: top;
        color: #E88873;
        transition: .4s;
    }

    .link:hover {
        text-decoration: none;
        border-bottom: 1px solid #E88873;
        cursor: pointer;
    }

}
  