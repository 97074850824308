@media (max-width: 1024px) {
    body { background-size: 5vw; background-position: 0px 0px; }
    .underline { text-decoration: underline; }
    .uppercase { text-transform: uppercase; }
    .textCenter { text-align: center; }
    .textJustify { text-align: justify; }
    .blockDriverImage { margin: 3px; width: 40px; height: 40px; background: #2d2d31; border-radius: 50%}
    .blockDriver1 { display: grid; grid-template-columns: 50px auto; margin-bottom: 0px; transition: .4s; padding: 12px 22px }
    .blockDriver .ty2 { margin-bottom: 10px; color: #aaa; font-size: 0.8em; padding: 12px 18px 0 18px; width: 186px; text-transform: uppercase; }
    .blockDriver .ty { margin-bottom: 10px; color: #aaa; font-size: 0.6em; padding: 12px 18px 0 18px; width: 186px; text-transform: uppercase; }
    .blockDriver3 { color: #fff; font-size: 1.1em; }
    .blockDriver1:active { background: #202124; cursor: pointer; }
    .blockDriverTrack { color: #aaa; font-size: 0.8em; }
}
@media (min-width: 1024px) {
    body { background-size: 5vw; background-position: 0px 0px; }
    .underline { text-decoration: underline; }
    .uppercase { text-transform: uppercase; }
    .textCenter { text-align: center; }
    .textJustify { text-align: justify; }
    .blockDriver { position: fixed; top: 80px; height: calc(100vh - 80px); right: 0px; width: 290px; overflow: scroll; }
    .blockDriverImage { margin: 3px; width: 40px; height: 40px; background: #2d2d31; border-radius: 50%}
    .blockDriver1 { display: grid; grid-template-columns: 50px auto; margin-bottom: 0px; transition: .4s; border-radius: 10px; padding: 9px 18px; width: 236px }
    .blockDriver .ty2 { margin-bottom: 10px; color: #aaa; font-size: 0.8em; padding: 12px 18px 0 18px; width: 236px; text-transform: uppercase; }
    .blockDriver .ty { margin-bottom: 10px; color: #aaa; font-size: 0.6em; padding: 12px 18px 0 18px; width: 236px; text-transform: uppercase; }
    .blockDriver3 { color: #fff; font-size: 1.1em; }
    .blockDriver1:hover { background: #202124; cursor: pointer; }
    .blockDriverTrack { color: #aaa; font-size: 0.8em; }
    .blockDriver { scrollbar-width: none; }
}
  