@media (max-width: 1024px) {}

@media (min-width: 1024px) {

    .name {
        display: inline;
        color: #e88873;
    }

    .name:hover {
        border-bottom: 1px solid #e88873;
        cursor: pointer;
    }

    .skelet {
        position: absolute;
        display: inline;
        bottom: 7px;
        width: 200px;
        height: 26px;
        background: #333;
        border-radius: 10px;
    }

}
  