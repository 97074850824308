h1, h2 {
    color: #fff;
}

h1 {
    font-size: 2em;
    cursor: pointer;
}

@media (max-width: 1024px) {}

@media (min-width: 1024px) {}
  