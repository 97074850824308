h2, p {
    color: #fff;
}

@media (max-width: 1024px) {}

@media (min-width: 1024px) {
    .content {
        position: relative;
        float: right;
        width: calc(100vw - 320px);
        height: 100%;
        display: grid;
        grid-template-columns: 20% auto;
        grid-area: content;
    }
    .list {
        height: 20%;
        overflow-y: scroll;
    }
    .createContent {
        height: 200px;
    }
}
  