@media (max-width: 1023px) {

    .container {
        margin:0;
        height: 100vh;
        overflow-y: hidden;
    }

    .loginForm {
        padding: 20px;
        padding-top: 80px;
        height: 100%;
        background: var(--tileColor);
    }

    .loginForm h1 {
        margin-bottom: 40px;
    }

    .loginForm label {
        font-size: 0.9em;
    }

    .loginForm .input {
        display: block;
        margin-top: 10px;
        margin-bottom: 15px;
        padding: var(--paddingSmallButton);
        width: calc(100% - 34px);
        font-size: 1.2em;
        background: transparent;
        color: var(--primary);
        border: 1px solid #5d5d5d;
        border-radius: var(--radius);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid transparent;
        -webkit-text-fill-color: var(--primary);
        -webkit-box-shadow: 0 0 0px 1000px var(--tileColor) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .loginForm .redirect {
        margin: 40px 0 20px 0;
        padding-left: 0;
        padding-right: 0;
        display: block;
        background: transparent;
        font-size: 0.9em;
        color: var(--secondary);
        border: none;
        border-bottom: 1px solid transparent;
        cursor: pointer;
    }

    .loginForm .redirect:hover {
        border-bottom: 1px solid var(--secondary);
    }

    .cta {
        position: absolute;
        left: 20px;
        bottom: 20px;
        padding: 20px;
        width: calc(100% - 40px);
        background: #20212460;
        color: #fff;
        font-size: 1.2em;
        border: 1px solid #5d5d5d;
        border-radius: var(--radius);
        transition: var(--transitionSpeed);
        cursor: pointer;
    }

    .cta:hover {
        background: #000;
    }

    .links, .image {
        display: none;
    }

}

/*@media (min-width: 1024px) {

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .loginForm {
        padding: 40px;
        background: var(--tileColor);
        border-radius: var(--radius);
    }

    .loginForm h1 {
        margin-bottom: 40px;
    }

    .loginForm label {
        font-size: 0.9em;
    }

    .loginForm .input {
        display: block;
        margin-top: 10px;
        margin-bottom: 15px;
        padding: var(--paddingSmallButton);
        width: calc(100% - 34px);
        font-size: 1.2em;
        background: transparent;
        color: var(--primary);
        border: 1px solid #5d5d5d;
        border-radius: var(--radius);
    }

    .loginForm .redirect {
        margin: 40px 0 20px 0;
        padding-left: 0;
        padding-right: 0;
        display: block;
        background: transparent;
        font-size: 0.9em;
        color: var(--secondary);
        border: none;
        border-bottom: 1px solid transparent;
        cursor: pointer;
    }

    .loginForm .redirect:hover {
        border-bottom: 1px solid var(--secondary);
    }

    .cta {
        padding: var(--paddingSmallButton);
        width: var(--smallButtonWidth);
        background: #ffffff;
        border: none;
        border-radius: var(--radius);
        transition: var(--transitionSpeed);
        cursor: pointer;
    }

    .cta:hover {
        padding: var(--paddingSmallButton);
        width: var(--smallButtonWidth);
        background: #E88873;
        border: none;
        border-radius: var(--radius);
    }

}*/

@media (min-width: 1024px) {

    .page {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: #0d1117;
        overflow: hidden;
    }

    .loginForm {
        padding: 40px;
        width: 400px;
        //background: #20212460;
        //border: 1px solid #5d5d5d;
        border-radius: var(--radius); 
    }

    .loginForm h1 {
        margin-bottom: 40px;
    }

    .loginForm label {
        font-size: 0.9em;
    }

    .loginForm .input {
        display: block;
        margin-top: 10px;
        margin-bottom: 15px;
        padding: var(--paddingSmallButton);
        width: calc(100% - 34px);
        font-size: 1.2em;
        background: transparent;
        color: var(--primary);
        border: 1px solid #5d5d5d;
        border-radius: var(--radius);
    }

    input:-webkit-autofill,
    /*input:-webkit-autofill:hover,*/
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid transparent;
        -webkit-text-fill-color: var(--primary);
        -webkit-box-shadow: 0 0 0px 1000px var(--tileColor) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .loginForm .redirect {
        margin: 40px 0 20px 0;
        padding-left: 0;
        padding-right: 0;
        display: block;
        background: transparent;
        font-size: 0.9em;
        color: var(--secondary);
        border: none;
        border-bottom: 1px solid transparent;
        cursor: pointer;
    }

    .loginForm .redirect:hover {
        border-bottom: 1px solid var(--secondary);
    }

    .cta {
        padding: 10px;
        width: 100%;
        background: #2d2d3140;
        color: #fff;
        font-size: 1.2em;
        border: 1px solid #5d5d5d;
        border-radius: var(--radius);
        transition: var(--transitionSpeed);
        cursor: pointer;
    }

    .cta:hover {
        //background: #E88873;
        background: #2d2d3180;
        //border: 1px solid #E88873;
        border: 1px solid #5d5d5d;
        //color: #000;
    }

    .image {
        height: 100%;
    }

    .links {
        margin-top: 40px;
        color: #ddd;
        font-size: 0.9em;
        border-bottom: 1px solid transparent;
        transition: .2s;
    }

    .links a:hover {
        border-bottom: 1px solid #fff;
    }

    .separator {
        margin: 0 10px;
        display: inline-block;
        width: 3px;
        height: 3px;
        background: #aaa;
        border-radius: 50%;
    }

}