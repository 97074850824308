@media (max-width: 1024px) {}
@media (min-width: 1024px) {
    .modalJoin { position: fixed; justify-content: center; align-items: center; top: 0; width: 100vw; height: 100vh; color: #fff; background: #00000099; z-index: 10000; }
    .modalJoinContainer { padding: 8px 16px; position: fixed; width: 600px; height: 220px; color: #fff; background: #202124; border-radius: 10px; box-shadow: 0 0 50px #000; }
    .modalJoinConteinerTitle { font-size: 1.4em; }
    .modalJoinConteinerSubtitle { margin-top: 24px; font-size: 1.2em; }
    .modalJoinConteinerDescription { color: #aaa; }
    .modalJoinConteinterCmlink a { color: #E88873 }
    .modalJoinConteinterCmlink a:hover { border-bottom: 1px solid #E88873; }
    .modalJoinConteinerBtnClose { position: absolute; bottom: 10px; right: 10px; padding: 10px 20px; color: #fff;  background: #2D2D31; border-radius: 6px; border: none; transition: .4s; }
    .modalJoinConteinerBtnClose:hover { color: #000; background: #E88873; cursor: pointer }
}
  