@media (max-width: 1024px) {
    /*.server { display: none; color: #fff; }
    .serverList { margin-top: 102px; border-radius: 8px; }
    .server-container { margin: 0 auto; padding: 24px; transition: .4s; }
    .server-container { margin-bottom: 42px; background: #202124; }
    .server-title { position: relative; margin: 20px 0 20px 0; height: 44px; font-size: 2em; }
    .server-title-skelet { position: absolute; bottom: 0; width: 200px; height: 26px; background: grey; border-radius: 10px; }
    .server-image-container { padding: 20px; }
    .server-container .server-image-container { width: calc(100% - 40px); height: 160px; background: grey; background-position: center; background-size: cover; border-radius: 10px; }
    /*.server-container:nth-child(1) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/drift.jpg"); background-position: center; background-size: cover; border-radius: 10px; }
    .server-container:nth-child(2) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/drift-playground.jpg"); background-position: center; background-size: cover; border-radius: 10px; }
    .server-container:nth-child(4) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/fort-51.jpg"); background-position: center; background-size: cover; border-radius: 10px; }
    .server-container:nth-child(5) .server-image-container { width: calc(100% - 40px); height: 160px; background: url("../images/klutch-kickers.jpg"); background-position: center; background-size: cover; border-radius: 10px; }*/
    /*.btn-join { padding: 12px 24px; width: 84px; background: #2D2D31; color: #fff; font-size: 1.2em; border: none; border-radius: 6px; transition: .2s; }
    .btn-join:hover { background: #E88873; color: #000; cursor: pointer; }
    .server-header-container { display: grid; grid-template-columns: auto 84px; }
    .server-btn-join-container { display: flex; justify-content: center; align-items: center; }
    .server-btnLink-container { display: block; }*/
}
@media (min-width: 1024px) {
    .loading {
        margin: 0 auto;
        margin-bottom: 20px;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1000px;
        height: 160px;
        transition: .4s;
        font-size: 1.6em;
        color: #fff;
        background: #202124;
        border-radius: 8px;
    }

}
  