body, h2 {
    color: #fff;
}

.calendar {
    color: #fff !important;
}

.active {
    border: 5px solid red;
}

.hidden {
    display: none;
}

@media (max-width: 1024px) {}

@media (min-width: 1024px) {

    .newServerContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .track {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border: 1px solid #444;
    }

    .layouts {
        display: block;
    }

}
  