h2 {
    color: #fff;
}

@media (max-width: 1024px) {}

@media (min-width: 1024px) {

    .container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }

    .track {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border: 1px solid #444;
    }

    .layouts {
        display: block;
    }

}
  