.blockList {
    position: relative;
    margin: 50px auto;
    width: var(--blockWidth);
    //height: calc(100vh - 87px);
    color: #fff;
    overflow: hidden;
}

.blockListContainer {
    position: relative;
    margin: 0 auto;
    width: var(--blockWidth);
    height: calc(100vh - 87px);
    color: #fff;
    overflow: scroll;
}

/*.blockListShader {
    position: sticky;
    width: var(--blockWidth);
    height: 100px;
    z-index: 1000;
}

.blockListShaderTop {
    top: 0;
    background-image: linear-gradient(to top, #0d111700, #0d1117);
}

.blockListShaderBottom {
    bottom: -1px;
    background-image: linear-gradient(to bottom, #0d111700, #0d1117);
}*/