h2, h3 {
    color: #fff;
}

.carPackContainer:hover {
    border: 2px solid #eeeeee60;
}

.carPackGridContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    color: #fff;
}

h3 {
    margin: 0;
    font-size: 1.3em;
    line-height: 40px;
}

.btnJoin {
    position: absolute;
    bottom: 16px;
    padding: 7px 24px;
    width: calc(100% - 32px);
    height: 52px; background: #2D2D31; color: #fff; font-size: 1.1em; border: none; border-radius: 6px; transition: .2s; }
    
    .btnJoin:hover { background: #E88873; color: #000; cursor: pointer; }

@media (max-width: 768px) {

    .stepTitle {
        margin-top: 80px;
        color: #E88873;
        text-align: center;
        font-size: 2.2em;
    }

    .gridContainer {
        margin: 16px;
    }

    .carPackContainer {
        margin-bottom: 16px;
        padding: 16px;
        position: relative;
        display: block;
        height: 30vh;
        background: #202124;
        border: 2px solid #202124;
        border-radius: 10px;
        transition: .4s;
    }

}

@media (min-width: 769px) and (max-width: 1024px) {

    .stepTitle {
        margin-top: 80px;
        color: #E88873;
        text-align: center;
        font-size: 3em;
    }

    .gridContainer {
        margin: 32px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 32px;
    }

    .carPackContainer {
        padding: 16px;
        position: relative;
        display: inline-block;
        height: 42vh;
        background: #202124;
        border: 2px solid #202124;
        border-radius: 10px;
        transition: .4s;
    }

}

@media (min-width: 1024px) and (max-width: 1350px) {

    .stepTitle {
        margin-top: 80px;
        color: #E88873;
        text-align: center;
        font-size: 3em;
    }

    .gridContainer {
        margin: 32px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px;
    }

    .carPackContainer {
        padding: 16px;
        position: relative;
        display: inline-block;
        height: 30vh;
        background: #202124;
        border: 2px solid #202124;
        border-radius: 10px;
        transition: .4s;
    }

}

@media (min-width: 1351px) and (max-width: 1740px) {

    .stepTitle {
        margin-top: 80px;
        color: #E88873;
        text-align: center;
        font-size: 3em;
    }

    .gridContainer {
        margin: 32px;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 32px;
    }

    .carPackContainer {
        padding: 16px;
        position: relative;
        display: inline-block;
        height: 30vh;
        background: #202124;
        border: 2px solid #202124;
        border-radius: 10px;
        transition: .4s;
    }

}

@media (min-width: 1741px) {

    .stepTitle {
        margin-top: 80px;
        color: #E88873;
        text-align: center;
        font-size: 3em;
    }

    .gridContainer {
        margin: 100px;
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 60px;
    }

    .carPackContainer {
        padding: 16px;
        position: relative;
        display: inline-block;
        height: 30vh;
        background: #202124;
        border: 2px solid #202124;
        border-radius: 10px;
        transition: .4s;
    }

}
  